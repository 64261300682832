import { HiOutlineClipboardList, HiOutlineCog, HiOutlineCurrencyDollar, HiOutlineInbox, HiOutlineMenu, HiOutlineUsers, HiOutlineViewGrid } from 'react-icons/hi';
import { NavLink } from 'react-router-dom';
import DarkModeToggle from '../DarkModeToggle';
import logo from './../../asset/logo.svg';
import logow from './../../asset/logow.svg';
import './index.scss';
export default function Menu() {
    const theme = localStorage.getItem('theme')
    
    return (
        <div className={`menu ${theme}`}>
            <div className="logo">
                {theme === 'dark' ? <img src={logow} alt="Logo" /> : <img src={logo} alt="Logo" />}
            </div>
            <div className="container border-bottom pb-3">
                <div className="font-14 opacity-75 mb-2 marl">
                    Menu
                </div>
                <nav className="nav flex-column">
                    <NavLink to="/" className="nav-link d-flex align-items-center">
                        <HiOutlineViewGrid className="icon" />
                        Painel
                    </NavLink>
                    <NavLink to="/categories" className="nav-link d-flex align-items-center">
                        <HiOutlineMenu className="icon" />
                        Categoria
                    </NavLink>
                    <NavLink to="/products" className="nav-link d-flex align-items-center">
                        <HiOutlineInbox className="icon" />
                        Produto
                    </NavLink>
                    <NavLink to="/clients" className="nav-link d-flex align-items-center">
                        <HiOutlineUsers className="icon" />
                        Clientes
                    </NavLink>
                    <NavLink to="/billing" className="nav-link d-flex align-items-center">
                        <HiOutlineCurrencyDollar className="icon" />
                        Faturação
                    </NavLink>
                    <NavLink to="/saft" className="nav-link d-flex align-items-center">
                        <HiOutlineClipboardList className="icon" />
                        Saft
                    </NavLink>
                    <NavLink to="/users" className="nav-link d-flex align-items-center">
                        <HiOutlineUsers className="icon" />
                        Utilizadores
                    </NavLink>
                </nav>
            </div>
            <div className="container pt-4">
                <div className="font-14 opacity-75 mb-2 marl">
                    Suporte
                </div>
                <nav className="nav flex-column">
                    <NavLink to="/settings" className="nav-link disabled d-flex align-items-center opacity-50">
                        <HiOutlineCog className="icon" />
                        Configurações
                    </NavLink>
                    <DarkModeToggle/>
                </nav>
            </div>
        </div>
    );
}
