import { useEffect, useState } from 'react';
import { Dropdown, Modal, Spinner } from 'react-bootstrap';
import { HiDotsHorizontal, HiPlus } from 'react-icons/hi';
import Menu from '../../components/menu';
import { NavTop } from '../../components/navTop';
import Table from '../../components/table';
import { TableRow } from '../../interface/tab';
import { api } from '../../services/api';
import './style.scss';

export default function Users() {

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => {
        setIsEdit(false);
        setNewUser({ id: null, name: '', email: '', password: '', password_confirmation: '', company_id: 1 });
        setShow(true);
    };

    const [rows, setRows] = useState<TableRow[]>([]);
    const [newUser, setNewUser] = useState({ id: null, name: '', email: '', password: '', password_confirmation: '', company_id: 1 });
    const [isEdit, setIsEdit] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const columns = ['#', 'Nome', 'Email', 'Empresa', 'Data de Criação', 'Opções'];

    useEffect(() => {
        loadUsers();
    }, []);

    async function loadUsers() {
        try {
            const response = await api.get('/users');
            const users = response.data.data;

            const formattedRows = users.map((user: any, index: number) => ({
                id: user.id,
                data: [
                    user.name,
                    user.email,
                    user.company_id, // Assumindo que company_id é exibido diretamente, ajuste conforme necessário
                    new Date(user.created_at).toLocaleDateString('pt-PT'),
                    <div>
                        <Dropdown>
                            <Dropdown.Toggle className='table-dropdown'>
                                <HiDotsHorizontal />
                            </Dropdown.Toggle>

                            <Dropdown.Menu className='table-dropdown-menu navDropdownToggle'>
                                <Dropdown.Item className='font-14' onClick={() => handleEdit(user)}>Editar</Dropdown.Item>
                                <Dropdown.Item className='font-14' onClick={() => handleDelete(user.id)}>Deletar</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    </div>
                ]
            }));
            setRows(formattedRows);
        } catch (error) {
            console.error("Error fetching users:", error);
        }
    }

    async function handleSave() {
        setIsLoading(true);
        if (isEdit) {
            await editUser();
        } else {
            await addUser();
        }
        setIsLoading(false);
    }

    async function editUser() {
        try {
            await api.put(`/users/${newUser.id}`, newUser);
            loadUsers();
            handleClose();
        } catch (error) {
            console.error("Error editing user:", error);
        }
    }

    async function handleDelete(id: number) {
        try {
            await api.delete(`/users/${id}`);
            loadUsers();
        } catch (error) {
            console.error("Error deleting user:", error);
        }
    }

    async function addUser() {
        try {
            await api.post('/users', {
                name: newUser.name,
                email: newUser.email,
                password: newUser.password,
                password_confirmation: newUser.password_confirmation,
                company_id: newUser.company_id
            });

            loadUsers();
            handleClose();
        } catch (error) {
            console.error("Error adding user:", error);
        }
    }

    function handleEdit(user: any) {
        setNewUser({ ...user, password: '', password_confirmation: '' });
        setIsEdit(true);
        setShow(true);
    }

    return (
        <main className='d-flex'>
            <Menu />
            <section className='w-100'>
                <NavTop title={'Utilizadores do sistema'} />
                <div className="container ps-4 pe-4 mt-5">
                    <Table
                        columns={columns}
                        rows={rows}
                        title={'Utilizadores do sistema'}
                        btn={<button type="button" className="btn btn-dark" onClick={handleShow}><HiPlus /></button>}
                        heighTable={350}
                        btnGroup={undefined}
                        cardTopLeftRadius={10}
                    />
                </div>
            </section>
            <Modal show={show} onHide={handleClose} backdrop="static" keyboard={false} className='modalprs'>
                <Modal.Header closeButton className='border-0'>
                    <h5 className='mt-2 mb-0'>{isEdit ? 'Editar Utilizador' : 'Cadastrar Utilizador'}</h5>
                </Modal.Header>
                <Modal.Body>
                    <div className="input-group mb-3">
                        <span className="input-group-text">Nome</span>
                        <input
                            type="text"
                            className="form-control"
                            placeholder="Nome do Utilizador"
                            value={newUser.name}
                            onChange={(e) => setNewUser({ ...newUser, name: e.target.value })}
                        />
                    </div>
                    <div className="input-group mb-3">
                        <span className="input-group-text">Email</span>
                        <input
                            type="email"
                            className="form-control"
                            placeholder="Email do Utilizador"
                            value={newUser.email}
                            onChange={(e) => setNewUser({ ...newUser, email: e.target.value })}
                        />
                    </div>
                    {!isEdit && (
                        <>
                            <div className="input-group mb-3">
                                <span className="input-group-text">Senha</span>
                                <input
                                    type="password"
                                    className="form-control"
                                    placeholder="Senha"
                                    value={newUser.password}
                                    onChange={(e) => setNewUser({ ...newUser, password: e.target.value })}
                                />
                            </div>
                            <div className="input-group mb-3">
                                <span className="input-group-text">Confirmar Senha</span>
                                <input
                                    type="password"
                                    className="form-control"
                                    placeholder="Confirme a Senha"
                                    value={newUser.password_confirmation}
                                    onChange={(e) => setNewUser({ ...newUser, password_confirmation: e.target.value })}
                                />
                            </div>
                        </>
                    )}
                    <div className="input-group mb-3">
                        <span className="input-group-text">Empresa ID</span>
                        <input
                            type="text"
                            className="form-control"
                            placeholder="ID da Empresa"
                            value={newUser.company_id}
                            onChange={(e) => setNewUser({ ...newUser, company_id: parseInt(e.target.value) })}
                        />
                    </div>
                </Modal.Body>
                <Modal.Footer className='border-0'>
                    <button className='btn btn-secondary' onClick={handleClose} disabled={isLoading}>
                        Cancelar
                    </button>
                    <button className='btn btn-dark' onClick={handleSave} disabled={isLoading}>
                        {isLoading ? <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" /> : 'Salvar Alterações'}
                    </button>
                </Modal.Footer>
            </Modal>
        </main>
    );
}
