import React from 'react'
import './style.scss'
import Table from '../../components/table';
import Menu from '../../components/menu';
import { NavTop } from '../../components/navTop';
import { HiOutlineUser, HiOutlineCalendar } from 'react-icons/hi';
export default function Saft() {
  return (
    <main className='d-flex saft'>
      <Menu />
      <section className='w-100'>
        <NavTop title={'Saft'} />
        <div className="container ps-4 pe-4 mt-5">
          <div className="card border-0">
            <div className="card-body">
              <div className="container p-0">
                <div className="row">
                  <div className="col-md-4 pt-5 pb-5">
                    <h5 className='mb-3'>Exportar o ficheiro SAF-T</h5>
                    <form action="">
                      <div className="input-group mb-3">
                        <span className="input-group-text"><HiOutlineCalendar /></span>
                        <select className="form-select" id="floatingSelect" aria-label="Floating label select example">
                          <option selected>Ano</option>
                          <option value="1">One</option>
                          <option value="2">Two</option>
                          <option value="3">Three</option>
                        </select>
                      </div>
                      <div className="input-group mb-3">
                        <span className="input-group-text"><HiOutlineCalendar /></span>
                        <select className="form-select" id="floatingSelect" aria-label="Floating label select example">
                          <option selected>Mes</option>
                          <option value="1">One</option>
                          <option value="2">Two</option>
                          <option value="3">Three</option>
                        </select>                      </div>
                        <button type="button" className="btn btn-primary">Exporta</button>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  )
}
