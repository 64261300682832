import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import { Bounce, ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import PrivateRoute from "./components/PrivateRoute";
import { AuthProvider } from "./contexts/AuthContext";
import Billing from './page/billing';
import Categories from './page/categories';
import Clients from './page/Clients';
import Dashboard from './page/dashboard';
import Login from './page/login';
import Plane from './page/Plane';
import Products from './page/products';
import Register from './page/register';
import Saft from './page/saft';
import Users from './page/users';

function App() {
  return <>
    <AuthProvider>
      <Router>
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
          transition={Bounce}
        />
        <ToastContainer />

        <Routes>
          <Route path='/login' element={<Login />} />
          <Route path='/register' element={<Register />} />
          <Route element={<PrivateRoute />}>
            <Route path='/' element={<Dashboard />} />
            <Route path='/categories' element={<Categories />} />
            <Route path='/products' element={<Products />} />
            <Route path='/clients' element={<Clients />} />
            <Route path='/billing' element={<Billing />} />
            <Route path='/saft' element={<Saft />} />
            <Route path='/users' element={<Users />} />
            <Route path='/plane' element={<Plane />} />
          </Route>
        </Routes>
      </Router>
    </AuthProvider>
  </>
}
export default App;
