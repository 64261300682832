import React, { createContext, ReactNode, useContext, useEffect, useState } from 'react';
import { api } from '../services/api';

interface AuthContextData {
    isAuthenticated: boolean;
    login: (credentials: Credentials) => Promise<boolean>;
    logout: () => void;
}

interface Credentials {
    email: string;
    password: string;
}

const AuthContext = createContext<AuthContextData>({} as AuthContextData);

export const AuthProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);

    useEffect(() => {
        const token = localStorage.getItem('access_token');
        if (token) {
            setIsAuthenticated(true);
            api.defaults.headers.common['Authorization'] = `Bearer ${token}`;
        }
    }, []);

    const login = async (credentials: Credentials): Promise<boolean> => {
        try {
            const response = await api.post('/auth/login', credentials);
            if (response.status === 200) {
                const { access_token, refresh_token } = response.data.auth_data;
                localStorage.setItem('access_token', access_token);

                api.defaults.headers.common['Authorization'] = `Bearer ${access_token}`;
                setIsAuthenticated(true);
                return true;
            } else {
                return false;
            }
        } catch (error) {
            console.error("Error during login:", error);
            return false;
        }
    };

    const logout = () => {
        localStorage.removeItem('access_token');
        setIsAuthenticated(false);
    };

    return (
        <AuthContext.Provider value={{ isAuthenticated, login, logout }}>
            {children}
        </AuthContext.Provider>
    );
};

export const useAuth = (): AuthContextData => {
    return useContext(AuthContext);
};
