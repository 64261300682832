import React, { useState } from 'react';
import './style.scss';
import { TableProps } from '../../interface/tab';
import { HiChevronDoubleLeft, HiChevronDoubleRight, HiDotsHorizontal, HiOutlineNewspaper, HiPlus, HiSearch } from 'react-icons/hi';
import { Dropdown } from 'react-bootstrap';

const Table: React.FC<TableProps> = ({ columns, rows, title, btn, heighTable, btnGroup, cardTopLeftRadius, }) => {
    const [currentPage, setCurrentPage] = useState(1);
    const rowsPerPage = 20;

    const indexOfLastRow = currentPage * rowsPerPage;
    const indexOfFirstRow = indexOfLastRow - rowsPerPage;
    const currentRows = rows.slice(indexOfFirstRow, indexOfLastRow);

    const totalPages = Math.ceil(rows.length / rowsPerPage);

    const handlePrevPage = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    const handleNextPage = () => {
        if (currentPage < totalPages) {
            setCurrentPage(currentPage + 1);
        }
    };

    const renderStatus = (status: string) => {
        let badgeClass = '';

        switch (status) {
            case 'Ativo':
                badgeClass = 'badge-status-primary';
                break;
            case 'Em Estoque':
                badgeClass = 'badge-status-info';
                break;
            case 'Pendente':
                badgeClass = 'badge-status-warning';
                break;
            case 'Esgotado':
                badgeClass = 'badge-status-danger';
                break;
            case 'Em Revisão':
                badgeClass = 'badge-status-secondary';
                break;
            case 'Inativo':
                badgeClass = 'badge-status-danger';
                break;
            case 'Pago':
                badgeClass = 'badge-status-success';
                break;

            default:
                badgeClass = 'badge-status-dark';
                break;

        }
        return <span className={`${badgeClass} ps-4 pe-4`}>{status}</span>;
    };


    return (
        <div>
            {btnGroup}
            <div className="card tabled mt-4" style={{borderTopLeftRadius: `${cardTopLeftRadius}px`}}>
                <div className="card-body  ps-4 pe-4 d-flex justify-content-between">
                    <div className='pt-2'>
                        <h6><b>{title}</b></h6>
                    </div>
                    <div className='d-flex'>
                        <div className="input-group input-group-search">
                            <span className="input-group-text" id="basic-addon1">
                                <HiSearch />
                            </span>
                            <input type="text" className="form-control" placeholder="Pesquisar" />
                        </div>
                        <div className='ms-2'>
                            {btn}
                        </div>
                        <div className='ms-2'>
                            <button type="button" className="btn btn-dark">
                                <HiOutlineNewspaper />
                            </button>
                        </div>
                    </div>
                </div>
                <div className="height-table" style={{ maxHeight: `${heighTable}px`, minHeight: `${heighTable}px` }}>
                    <table className="table">
                        <thead>
                            <tr>
                                <th className='ps-4'>
                                    <input className="form-check-input" type="checkbox" value="" />
                                </th>
                                {columns.map((col, index) => (
                                    <th key={index}>{col}</th>
                                ))}
                                <th className='pe-4 text-right'>Opções</th>
                            </tr>
                        </thead>
                        <tbody>
                            {currentRows.map((row) => (
                                <tr key={row.id}>
                                    <td className='ps-4'>
                                        <input className="form-check-input" type="checkbox" value="" />
                                    </td>
                                    <td>{row.id}</td>
                                    {row.data.map((cell, index) => (
                                        <td key={index}>{index === row.data.length - 1 ? renderStatus(cell) : cell}</td>
                                    ))}
                                    <td className='pe-4 text-right'>
                                        <Dropdown>
                                            <Dropdown.Toggle className='table-dropdown '>
                                                <HiDotsHorizontal />
                                            </Dropdown.Toggle>

                                            <Dropdown.Menu className='table-dropdown-menu navDropdownToggle'>
                                                <Dropdown.Item className='font-14' href="#/action-1">Action</Dropdown.Item>
                                                <Dropdown.Item className='font-14' href="#/action-2">Another action</Dropdown.Item>
                                                <Dropdown.Item className='font-14' href="#/action-3">Something else</Dropdown.Item>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
                <div className='card-footer pt-0 ps-4 pe-4 d-flex justify-content-between'>
                    <div>
                        <b>{indexOfFirstRow + 1}</b> até <b>{indexOfLastRow > rows.length ? rows.length : indexOfLastRow}</b> de <b>{rows.length}</b>
                    </div>
                    <div className='d-flex'>
                        <div className='ms-2'>
                            <button type="button" onClick={handlePrevPage} disabled={currentPage === 1}>
                                <HiChevronDoubleLeft />
                            </button>
                        </div>
                        <div className='ms-2'>
                            <button type="button" onClick={handleNextPage} disabled={currentPage === totalPages}>
                                <HiChevronDoubleRight />
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Table;
