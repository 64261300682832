import { useEffect, useState } from 'react';
import { Dropdown, Offcanvas, Toast } from 'react-bootstrap';
import { HiDotsHorizontal, HiOutlineClipboardList, HiOutlineCollection, HiOutlineCurrencyDollar, HiOutlineDocumentReport, HiOutlineDocumentText, HiOutlineTag, HiPlus } from 'react-icons/hi';
import Menu from '../../components/menu';
import { NavTop } from '../../components/navTop';
import Table from '../../components/table';
import { TableRow } from '../../interface/tab';
import { api } from '../../services/api';
import './style.scss';

export default function Products() {
  const [show, setShow] = useState(false);
  const [toastMessage, setToastMessage] = useState('');
  const [showToast, setShowToast] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [rows, setRows] = useState<TableRow[]>([]);
  const [categories, setCategories] = useState([]);
  const [newProduct, setNewProduct] = useState({ id: null, name: '', description: '', quantity: '', type: '', price: '', iva: '', status: '', category_id: '' });
  const [isEdit, setIsEdit] = useState(false);
  const [isLoadingUpdate, setIsLoadingUpdate] = useState(true);

  const columns = ['#', 'Nome', 'Descrição', 'Quantidade', 'Tipo', 'Preço', 'IVA', 'Status', 'Categoria', 'Opções'];

  useEffect(() => {
    loadProducts();
    loadCategories();
  }, []);

  async function loadProducts() {
    try {
      const response = await api.get('/products');
      const products = response.data.data;

      const formattedRows = products.map((product: any, index: number) => ({
        id: product.id,
        data: [product.name, product.description, product.quantity, product.type, product.price, product.iva, product.status, product.category_id,
        <div>
          <Dropdown>
            <Dropdown.Toggle className='table-dropdown'>
              <HiDotsHorizontal />
            </Dropdown.Toggle>
            <Dropdown.Menu className='table-dropdown-menu navDropdownToggle'>
              <Dropdown.Item className='font-14' onClick={() => handleEdit(product)}>Editar</Dropdown.Item>
              <Dropdown.Item className='font-14' onClick={() => handleDelete(product.id)}>Deletar</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
        ]
      }));
      setRows(formattedRows);
    } catch (error) {
      showToastMessage("Erro ao carregar produtos.");
      console.error("Error fetching products:", error);
    }
  }

  async function loadCategories() {
    try {
      const response = await api.get('/categories');
      setCategories(response.data.data);
    } catch (error) {
      showToastMessage("Erro ao carregar categorias.");
      console.error("Error fetching categories:", error);
    }
  }

  async function editProduct() {
    try {
      await api.put(`/products/${newProduct.id}`, newProduct);
      loadProducts();
      resetForm();
      handleClose();
      showToastMessage("Produto atualizado com sucesso!");
    } catch (error) {
      showToastMessage("Erro ao editar produto.");
      console.error("Error editing product:", error);
    }
  }

  async function handleDelete(id: number) {
    try {
      await api.delete(`/products/${id}`);
      loadProducts();
      showToastMessage("Produto deletado com sucesso!");
    } catch (error) {
      showToastMessage("Erro ao deletar produto.");
      console.error("Error deleting product:", error);
    }
  }

  async function addProduct() {
    try {
      await api.post('/products', newProduct);
      loadProducts();
      resetForm();
      handleClose();
      showToastMessage("Produto adicionado com sucesso!");
    } catch (error) {
      showToastMessage("Erro ao adicionar produto.");
      console.error("Error adding product:", error);
    }
  }

  function handleEdit(product: any) {
    setNewProduct(product);
    setIsEdit(true);
    handleShow();
  }

  function handleSave() {
    setIsLoadingUpdate(false);
    if (isEdit) {
      editProduct();
    } else {
      addProduct();
    }
    setIsLoadingUpdate(true);
  }

  function showAddForm() {
    setIsEdit(false);
    resetForm();
    handleShow();
  }

  function resetForm() {
    setNewProduct({ id: null, name: '', description: '', quantity: '', type: '', price: '', iva: '', status: '', category_id: '' });
  }

  function showToastMessage(message: string) {
    setToastMessage(message);
    setShowToast(true);
    setTimeout(() => setShowToast(false), 3000);
  }

  return (
    <main className='d-flex'>
      <Menu />
      <section className='w-100'>
        <NavTop title={'Produtos'} />
        <div className="container ps-4 pe-4 mt-5">
          <Table
            columns={columns}
            rows={rows}
            title={'Produtos'}
            btn={<button type="button" className="btn btn-dark" onClick={showAddForm}><HiPlus /></button>}
            heighTable={350} btnGroup={undefined} cardTopLeftRadius={10}
          />
        </div>
      </section>

      <Offcanvas show={show} onHide={handleClose} className="custom-offcanvas-width" placement="end">
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>{isEdit ? 'Editar Produto' : 'Criar Novo Produto'}</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <div className="container p-0">
            <div className="row">
              <div className="col-md-12 mb-3">
                <div className="input-group">
                  <span className="input-group-text"><HiOutlineTag /></span>
                  <input type="text" className="form-control" placeholder="Nome" value={newProduct.name} onChange={(e) => setNewProduct({ ...newProduct, name: e.target.value })} />
                </div>
              </div>
              <div className="col-md-12 mb-3">
                <div className="input-group">
                  <span className="input-group-text"><HiOutlineDocumentText /></span>
                  <textarea className="form-control" placeholder='Descrição' value={newProduct.description} onChange={(e) => setNewProduct({ ...newProduct, description: e.target.value })}></textarea>
                </div>
              </div>
              <div className="col-md-6 mb-3">
                <div className="input-group">
                  <span className="input-group-text"><HiOutlineCurrencyDollar /></span>
                  <input type="number" className="form-control" placeholder="Preço" value={newProduct.price} onChange={(e) => setNewProduct({ ...newProduct, price: e.target.value })} />
                </div>
              </div>
              <div className="col-md-6 mb-3">
                <div className="input-group">
                  <span className="input-group-text"><HiOutlineClipboardList /></span>
                  <select className="form-select" value={newProduct.category_id} onChange={(e) => setNewProduct({ ...newProduct, category_id: e.target.value })}>
                    <option value="">Categoria</option>
                    {categories.map((category: any) => (
                      <option key={category.id} value={category.id}>{category.name}</option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="col-md-6 mb-3">
                <div className="input-group">
                  <span className="input-group-text"><HiOutlineClipboardList /></span>
                  <input type="number" className="form-control" placeholder="Quantidade" value={newProduct.quantity} onChange={(e) => setNewProduct({ ...newProduct, quantity: e.target.value })} />
                </div>
              </div>
              <div className="col-md-6 mb-3">
                <div className="input-group">
                  <span className="input-group-text"><HiOutlineTag /></span>
                  <input type="text" className="form-control" placeholder="Tipo" value={newProduct.type} onChange={(e) => setNewProduct({ ...newProduct, type: e.target.value })} />
                </div>
              </div>
              
              <div className="col-md-6 mb-3">
                <div className="input-group">
                  <span className="input-group-text"><HiOutlineDocumentReport /></span>
                  <select className="form-select" value={newProduct.iva} onChange={(e) => setNewProduct({ ...newProduct, iva: e.target.value })}>
                    <option value="">IVA</option>
                    <option value="15%">15%</option>
                    <option value="10%">10%</option>
                    <option value="5%">5%</option>
                    <option value="0%">Isento</option>
                  </select>
                </div>
              </div>

              <div className="col-md-6 mb-3">
                <div className="input-group">
                  <span className="input-group-text"><HiOutlineCollection /></span>
                  <select className="form-select" value={newProduct.status} onChange={(e) => setNewProduct({ ...newProduct, status: e.target.value })}>
                    <option value="">Status</option>
                    <option value="Ativo">Ativo</option>
                    <option value="Inativo">Inativo</option>
                  </select>
                </div>
              </div>

              <div className="col-md-12">
                <button type="button" className="btn btn-primary" onClick={handleSave} disabled={!isLoadingUpdate}>
                  {isEdit ? 'Atualizar Produto' : 'Publicar Produto'}
                </button>
              </div>
            </div>
          </div>
        </Offcanvas.Body>
      </Offcanvas>

      <Toast show={showToast} onClose={() => setShowToast(false)} className="position-fixed bottom-0 end-0 m-3" bg="success">
        <Toast.Body>{toastMessage}</Toast.Body>
      </Toast>
    </main>
  );
}
