import React from 'react';
import { Dropdown } from 'react-bootstrap';
import { NavLink, useNavigate } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContext';
import './style.scss';
interface navTo {
  title: string;
}

export const NavTop: React.FC<navTo> = ({ title }) => {
  const { logout } = useAuth();
  const navigate = useNavigate();

  const handleLogout = () => {
    logout();
    navigate('/login');
  };

  return <div className='border-bottom pt-5 pb-3 navTop'>
    <div className='container ps-4 pe-4'>
      <div className='d-flex justify-content-between'>
        <h3>{title}</h3>
        <div>
          <Dropdown>
            <Dropdown.Toggle className='navDropdown' id="dropdown-basic">
              <div className='avatar'>
                <img src="https://i.pinimg.com/564x/47/af/04/47af04b319bbfe77f6358edd792062e6.jpg" alt="" />
              </div>
            </Dropdown.Toggle>

            <Dropdown.Menu className='navDropdownToggle'>
              <NavLink className="dropdown-item" to="#/action-1">Conta</NavLink>
              <NavLink className="dropdown-item" to="#/action-2">Forma de pagamento</NavLink>
              <NavLink className="dropdown-item" to="/plane">Planos</NavLink>
              <NavLink className="dropdown-item" to="#" onClick={handleLogout}>Terminar sessao</NavLink>
            </Dropdown.Menu>
          </Dropdown>

        </div>
      </div>
    </div>
  </div>
}
