import React, { useState } from 'react';
import './style.scss';
import { ButtonGroup } from 'react-bootstrap';
import { NavTop } from '../../components/navTop';
import Table from '../../components/table';
import Menu from '../../components/menu';
import { HiPlus } from 'react-icons/hi';

export default function Faturamento() {
  const [selectedTab, setSelectedTab] = useState('Fatura');
  const [rows, setRows] = useState(getRowsByTab('Fatura'));

  const columns = ['#', 'Documento', 'Data de Emissão', 'Cliente', 'Desconto', 'Total', 'Status'];

  function getRowsByTab(tabKey:any) {
    switch(tabKey) {
      case 'Fatura':
        return [
          { id: 1, data: ['INV2023001', '2023-01-15', 'Ana Silva', '10%', '1500.00', 'Pago'] },
          { id: 2, data: ['INV2023002', '2023-01-18', 'João Santos', '5%', '2500.00', 'Pago'] },
          { id: 3, data: ['INV2023003', '2023-01-20', 'Maria Fernandes', '15%', '3000.00', 'Pendente'] },
        ];
      case 'Fatura/Recibo':
        return [
          { id: 4, data: ['INV2023004', '2023-01-22', 'Pedro Almeida', '0%', '700.00', 'Pago'] },
          { id: 5, data: ['INV2023005', '2023-01-25', 'Sofia Costa', '20%', '400.00', 'Pago'] },
          { id: 6, data: ['INV2023006', '2023-01-28', 'Miguel Oliveira', '12%', '600.00', 'Em Revisão'] },
        ];
      case 'Proforma':
        return [
          { id: 7, data: ['INV2023007', '2023-02-01', 'Inês Ramos', '8%', '1200.00', 'Pago'] },
          { id: 8, data: ['INV2023008', '2023-02-05', 'Rui Cardoso', '25%', '300.00', 'Pago'] },
        ];
      case 'Orçamento':
        return [
          { id: 9, data: ['INV2023009', '2023-02-10', 'Carla Nunes', '10%', '500.00', 'Pago'] },
          { id: 10, data: ['INV2023010', '2023-02-15', 'Paulo Dias', '15%', '2000.00', 'Pago'] },
        ];
      case 'Guia de Remessa':
        return [
          { id: 11, data: ['INV2023011', '2023-02-20', 'José Carvalho', '0%', '350.00', 'Pago'] },
          { id: 12, data: ['INV2023012', '2023-02-25', 'Luis Gonçalves', '5%', '800.00', 'Pago'] },
        ];
      case 'Recibo':
        return [
          { id: 13, data: ['INV2023013', '2023-03-01', 'Marta Pereira', '18%', '1000.00', 'Pago'] },
          { id: 14, data: ['INV2023014', '2023-03-05', 'Tiago Silva', '20%', '450.00', 'Pago'] },
        ];
      default:
        return [];
    }
  }

  const handleTabClick = (tabKey:any) => {
    setSelectedTab(tabKey);
    setRows(getRowsByTab(tabKey));
  };

  const renderButtonGroup = () => {
    const tabs = [
      { key: 'Fatura', label: 'Fatura' },
      { key: 'Fatura/Recibo', label: 'Fatura/Recibo' },
      { key: 'Proforma', label: 'Proforma' },
      { key: 'Orçamento', label: 'Orçamento' },
      { key: 'Guia de Remessa', label: 'Guia de Remessa' },
      { key: 'Recibo', label: 'Recibo' },
    ];

    return (
      <ButtonGroup className="btn-group-blid" role="group" aria-label="Basic example">
        {tabs.map(tab => (
          <button
            type="button"
            className={`btn ${selectedTab === tab.key ? 'active' : ''}`}
            key={tab.key}
            onClick={() => handleTabClick(tab.key)}
          >
            {tab.label}
          </button>
        ))}
      </ButtonGroup>
    );
  };

  return (
    <main className='d-flex'>
      <Menu />
      <section className='w-100'>
        <NavTop title={'Faturamento'} />
        <div className="container ps-4 pe-4 mt-5">
          {renderButtonGroup()}
          <Table
            columns={columns}
            rows={rows}
            title={`Faturamento - ${selectedTab}`}
            btn={<button type="button" className="btn btn-dark"><HiPlus /></button>}
            heighTable={300}
            cardTopLeftRadius={0}
            btnGroup={undefined}
          />
        </div>
      </section>
    </main>
  );
}
