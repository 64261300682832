import React, { FormEvent, useEffect, useState } from 'react';
import { HiOutlineLockOpen, HiOutlineUser } from "react-icons/hi";
import { NavLink, useNavigate } from 'react-router-dom';
import { login } from '../../services/authService';
import './style.scss';

export default function Login() {
    const [darkMode, setDarkMode] = useState(false);
    const [credentials, setCredentials] = useState({ email: '', password: '' });
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        const savedMode = localStorage.getItem('theme');
        if (savedMode) {
            setDarkMode(savedMode === 'dark');
            document.body.classList.toggle('dark-mode', savedMode === 'dark');
        }
    }, []);

    async function handleLogin(event: FormEvent<HTMLFormElement>) {
        event.preventDefault();
        setLoading(true);
        const success = await login(credentials);
        setLoading(false);
        if (success) {
            navigate('/');
        }
    }

    function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
        const { name, value } = event.target;
        setCredentials(prevState => ({
            ...prevState,
            [name]: value
        }));
    }

    return (
        <main className="d-flex auth">
            <section className="w-100 h-100 bg" />
            <section className="w-100 h-100 d-flex align-items-center">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-md-6">
                            <h3>Profatura</h3>
                            <p>Bem-vindo! Por favor, faça login para continuar.</p>
                            <form onSubmit={handleLogin}>
                                <div className="input-group mb-3">
                                    <span className="input-group-text"><HiOutlineUser /></span>
                                    <input
                                        type="email"
                                        className="form-control"
                                        placeholder="Email"
                                        name="email"
                                        value={credentials.email}
                                        onChange={handleChange}
                                    />
                                </div>
                                <div className="input-group mb-3">
                                    <span className="input-group-text"><HiOutlineLockOpen /></span>
                                    <input
                                        type="password"
                                        className="form-control"
                                        placeholder="Senha"
                                        name="password"
                                        value={credentials.password}
                                        onChange={handleChange}
                                    />
                                </div>
                                <button type="submit" className="btn btn-primary w-100" disabled={loading}>
                                    {loading ? 'Carregando...' : 'Entrar'}
                                </button>
                                <div className="mt-4 text-center">
                                    Não tem uma conta? <NavLink className="a-link" to="/register">Criar conta</NavLink>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </section>
        </main>
    );
}
