import { useEffect, useState } from 'react';
import { Dropdown, Modal } from 'react-bootstrap';
import { HiDotsHorizontal, HiPlus } from 'react-icons/hi';
import { Bounce, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Menu from '../../components/menu';
import { NavTop } from '../../components/navTop';
import Table from '../../components/table';
import { TableRow } from '../../interface/tab';
import { api } from '../../services/api';
import './style.scss';

export default function Categories() {
  const [show, setShow] = useState(false);
  const [rows, setRows] = useState<TableRow[]>([]);
  const [newCategory, setNewCategory] = useState({ id: null, name: '', description: '' });
  const [isEdit, setIsEdit] = useState(false);
  const [isLoadingUpdate, setIsLoadingUpdate] = useState(false);
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const [categoryToDelete, setCategoryToDelete] = useState<number | null>(null);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const columns = ['#', 'Nome', 'Descrição', 'Opções'];

  useEffect(() => {
    loadCategories();
  }, []);

  async function loadCategories() {
    try {
      const response = await api.get('/categories');
      const categories = response.data.data;

      const formattedRows = categories.map((category: any, index: number) => ({
        id: category.id,
        data: [category.name, category.description,
        <div key={category.id}>
          <Dropdown>
            <Dropdown.Toggle className='table-dropdown'>
              <HiDotsHorizontal />
            </Dropdown.Toggle>

            <Dropdown.Menu className='table-dropdown-menu navDropdownToggle'>
              <Dropdown.Item className='font-14' onClick={() => handleEdit(category)}>Editar</Dropdown.Item>
              <Dropdown.Item className='font-14' onClick={() => confirmDeleteCategory(category.id)}>Deletar</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
        ]
      }));
      setRows(formattedRows);
    } catch (error) {
      console.error("Error fetching categories:", error);
      toast.error("Erro ao listar categorias.", { transition: Bounce });
    }
  }

  async function editCategory() {
    try {
      await api.put(`/categories/${newCategory.id}`, {
        name: newCategory.name,
        description: newCategory.description
      });
      loadCategories();
      setNewCategory({ id: null, name: '', description: '' });
      handleClose();
      toast.success("Categoria editada com sucesso!", { transition: Bounce });
    } catch (error) {
      console.error("Error editing category:", error);
      toast.error("Erro ao editar categoria.", { transition: Bounce });
    }
  }

  async function deleteCategory() {
    if (categoryToDelete !== null) {
      try {
        await api.delete(`/categories/${categoryToDelete}`);
        loadCategories();
        setCategoryToDelete(null);
        setShowDeleteConfirm(false);
        toast.success("Categoria deletada com sucesso!", { transition: Bounce });
      } catch (error) {
        console.error("Error deleting category:", error);
        toast.error("Erro ao deletar categoria.", { transition: Bounce });
      }
    }
  }

  async function addCategory() {
    try {
      await api.post('/categories', {
        name: newCategory.name,
        description: newCategory.description
      });
      loadCategories();
      setNewCategory({ id: null, name: '', description: '' });
      handleClose();
      toast.success("Categoria adicionada com sucesso!", { transition: Bounce });
    } catch (error) {
      console.error("Error adding category:", error);
      toast.error("Erro ao adicionar categoria.", { transition: Bounce });
    }
  }

  function handleEdit(category: any) {
    setNewCategory(category);
    setIsEdit(true);
    handleShow();
  }

  function handleSave() {
    setIsLoadingUpdate(true);
    if (isEdit) {
      editCategory().finally(() => setIsLoadingUpdate(false));
    } else {
      addCategory().finally(() => setIsLoadingUpdate(false));
    }
  }

  function showAddForm() {
    setIsEdit(false);
    setNewCategory({ id: null, name: '', description: '' });
    handleShow();
  }

  function confirmDeleteCategory(id: number) {
    setCategoryToDelete(id);
    setShowDeleteConfirm(true);
  }

  return (
    <main className='d-flex'>
      <Menu />
      <section className='w-100'>
        <NavTop title={'Categoria'} />
        <div className="container ps-4 pe-4 mt-5">
          <Table
            columns={columns}
            rows={rows}
            title={'Categorias'}
            btn={<button type="button" className="btn btn-dark" onClick={showAddForm}><HiPlus /></button>}
            heighTable={350}
            btnGroup={undefined}
            cardTopLeftRadius={10}
          />
        </div>
      </section>
      <Modal show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        className='modalprs'>
        <Modal.Header closeButton className='border-0'>
          <h5 className='mt-2 mb-0'>{isEdit ? 'Editar Categoria' : 'Cadastro de Categoria'}</h5>
        </Modal.Header>
        <Modal.Body>
          <div className="input-group mb-3">
            <span className="input-group-text" id="basic-addon1">Nome</span>
            <input
              type="text"
              className="form-control"
              placeholder="Nome da Categoria"
              aria-label="Nome da Categoria"
              aria-describedby="basic-addon1"
              value={newCategory.name}
              onChange={(e) => setNewCategory({ ...newCategory, name: e.target.value })}
            />
          </div>
          <div className="input-group mb-3">
            <span className="input-group-text" id="basic-addon2">Descrição</span>
            <input
              type="text"
              className="form-control"
              placeholder="Descrição da Categoria"
              aria-label="Descrição da Categoria"
              aria-describedby="basic-addon2"
              value={newCategory.description}
              onChange={(e) => setNewCategory({ ...newCategory, description: e.target.value })}
            />
          </div>
        </Modal.Body>
        <Modal.Footer className='border-0'>
          <button className='btn btn-secondary' onClick={handleClose}>
            Cancelar
          </button>
          <button className='btn btn-dark' onClick={handleSave} disabled={isLoadingUpdate}>
            {isLoadingUpdate ? 'Salvando...' : 'Salvar'}
          </button>
        </Modal.Footer>
      </Modal>

      <Modal show={showDeleteConfirm}
        onHide={() => setShowDeleteConfirm(false)}
        backdrop="static"
        keyboard={false}
        className='modalprs'>
        <Modal.Header closeButton className='border-0'>
          <h5 className='mt-2 mb-0'>Confirmar Exclusão</h5>
        </Modal.Header>
        <Modal.Body>
          <p>Tem certeza que deseja excluir esta categoria?</p>
        </Modal.Body>
        <Modal.Footer className='border-0'>
          <button className='btn btn-secondary' onClick={() => setShowDeleteConfirm(false)}>
            Cancelar
          </button>
          <button className='btn btn-danger' onClick={deleteCategory}>
            Deletar
          </button>
        </Modal.Footer>
      </Modal>
    </main>
  );
}
