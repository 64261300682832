import { FormEvent, useEffect, useState } from 'react';
import {
    HiOutlineGlobe,
    HiOutlineLocationMarker,
    HiOutlineLockOpen,
    HiOutlineNewspaper,
    HiOutlineOfficeBuilding,
    HiOutlinePhone
} from 'react-icons/hi';
import { NavLink } from 'react-router-dom';
import { api } from '../../services/api';
import './style.scss';

export default function Register() {
    const [darkMode, setDarkMode] = useState(false);
    const [newCompany, setNewCompany] = useState({ id: null, name: '', email: '', phone: '', nif: '', address: '', country_id: 1, password: '' });
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const savedMode = localStorage.getItem('theme');
        if (savedMode) {
            setDarkMode(savedMode === 'dark')
            document.body.classList.toggle('dark-mode', savedMode === 'dark');
        }
    }, []);

    async function registerCompany(event: FormEvent<HTMLFormElement>) {
        event.preventDefault();
        setLoading(true);
        try {
            await api.post('/auth/register', {
                name: newCompany.name,
                email: newCompany.email,
                phone: newCompany.phone,
                nif: newCompany.nif,
                address: newCompany.address,
                country_id: newCompany.country_id,
                password: newCompany.password,
                password_confirmation: newCompany.password,
            });
            // Reset form or show success message
        } catch (error) {
            console.error("Error adding customer:", error);
        } finally {
            setLoading(false);
        }
    }

    function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
        const { name, value } = event.target;
        setNewCompany(prevState => ({
            ...prevState,
            [name]: value
        }));
    }

    return (
        <main className="auth d-flex">
            <section className="bg w-100 h-100" />
            <section className="w-100 h-100 d-flex align-items-center">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-md-7">
                            <h3>Balanço Real</h3>
                            <p>Registre-se para acessar nossos serviços.</p>
                            <form onSubmit={registerCompany}>
                                <div className="input-group mb-3">
                                    <span className="input-group-text"><HiOutlineOfficeBuilding /></span>
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Nome da Empresa"
                                        name="name"
                                        value={newCompany.name}
                                        onChange={handleChange}
                                    />
                                </div>
                                <div className="input-group mb-3">
                                    <span className="input-group-text"><HiOutlineGlobe /></span>
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Site da Empresa"
                                        name="email"
                                        value={newCompany.email}
                                        onChange={handleChange}
                                    />
                                </div>
                                <div className="input-group mb-3">
                                    <span className="input-group-text"><HiOutlineNewspaper /></span>
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder="NIF da Empresa"
                                        name="nif"
                                        value={newCompany.nif}
                                        onChange={handleChange}
                                    />
                                </div>
                                <div className="row">
                                    <div className="col-6">
                                        <div className="input-group mb-3">
                                            <span className="input-group-text"><HiOutlinePhone /></span>
                                            <input
                                                type="number"
                                                className="form-control"
                                                placeholder="Telefone"
                                                name="phone"
                                                value={newCompany.phone}
                                                onChange={handleChange}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-6">
                                        <div className="input-group mb-3">
                                            <span className="input-group-text"><HiOutlineLocationMarker /></span>
                                            <input
                                                type="text"
                                                className="form-control"
                                                placeholder="Localização"
                                                name="address"
                                                value={newCompany.address}
                                                onChange={handleChange}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="input-group mb-3">
                                    <span className="input-group-text"><HiOutlineLockOpen /></span>
                                    <input
                                        type="password"
                                        className="form-control"
                                        placeholder="Senha"
                                        name="password"
                                        value={newCompany.password}
                                        onChange={handleChange}
                                    />
                                </div>
                                <button type="submit" className="btn btn-primary w-100" disabled={loading}>
                                    {loading ? 'Carregando...' : 'Criar Conta'}
                                </button>
                                <div className="mt-4 text-center">
                                    Já tem uma conta? <NavLink className="a-link" to="/">Entrar</NavLink>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </section>
        </main>
    );
}
