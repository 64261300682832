import React from 'react'
import './style.scss'
import Table from '../../components/table';
import Menu from '../../components/menu';
import { NavTop } from '../../components/navTop';
import { HiOutlineUser, HiOutlineCalendar } from 'react-icons/hi';
export default function Plane() {
  return (
    <main className='d-flex palne'>
      <Menu />
      <section className='w-100'>
        <NavTop title={'Planos'} />
        <div className="container ps-4 pe-4 mt-5">
          <div className="row">
            <div className="col-md-4 mb-4">
              <div className="card cards-planes border-0">
                <div className="card-body">
                  <span>Plano Semestral - 6 Meses</span>
                  <h4>20.000 Kz</h4>
                  <p>Benefícios por 6 meses.</p>
                  <button type="button" className="btn btn-primary">Assinar Plano</button>
                </div>
              </div>
            </div>
            <div className="col-md-4 mb-4">
              <div className="card cards-planes border-0">
                <div className="card-body">
                  <span>Plano Anual - 12 Meses</span>
                  <h4>40.000 Kz</h4>
                  <p>Benefícios por 12 meses.</p>
                  <button type="button" className="btn btn-primary">Assinar Plano</button>
                </div>
              </div>
            </div>
            <div className="col-md-4 mb-4">
              <div className="card cards-planes border-0">
                <div className="card-body">
                  <span>Plano Mensal - 1 Mês</span>
                  <h4>5.000 Kz</h4>
                  <p>Benefícios por 1 mês.</p>
                  <button type="button" className="btn btn-primary">Assinar Plano</button>
                </div>
              </div>
            </div>
            <div className="col-md-4 mb-4">
              <div className="card cards-planes border-0">
                <div className="card-body">
                  <span>Plano Grátis - 0 Meses</span>
                  <h4>Grátis</h4>
                  <p>Plano básico.</p>
                  <button disabled type="button" className="btn btn-primary">Plano Atual</button>
                </div>
              </div>
            </div>
          </div>
        </div>

      </section>
    </main>
  )
}
