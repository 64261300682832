import React, { useEffect, useState } from 'react'
import { CardsData } from '../../components/cardsData'
import { dataCard, usersData } from './db'
import './index.scss'
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, LineChart, Line } from 'recharts';
import { salesData } from './db';
import Menu from '../../components/menu';
import { NavTop } from '../../components/navTop';


export default function Dashboard() {
  const theme = localStorage.getItem('theme')
  const tickColor = theme === 'light' ? '#000' : '#EEF7FF';

  return <main className='d-flex'>
    <Menu />
    <section className='w-100'>
      <NavTop title={'Dashboard'} />
      <div className="container ps-4 pe-4 mt-5">
        <div className="row">
          {dataCard.map((item, index) => (
            <div className="col-lg-3">
              <CardsData
                key={index}
                title={item.title}
                count={item.count}
                percentage={item.percentage}
                day={item.day}
                status={item.status}
                icon={item.icon}
                color={item.color}
              />
            </div>
          ))}
        </div>
        <div className="row">
          <div className="col-lg-6 mb-4">
            <div className="card card-charts">
              <div className="card-header p-3">
                <h4 className="m-0 mb-1">Faturamento Mensal</h4>
                <p className="opacity-50 font-14">Dados de faturamento mensal ao longo do ano.</p>
              </div>
              <div className="card-body p-0 pb-2 pe-4 pb-4" style={{ width: '100%', height: 270 }}>
                <ResponsiveContainer>
                  <BarChart
                    width={500}
                    height={270}
                    data={salesData}
                    barSize={12}
                    className='cardsssaa'
                  >
                    <CartesianGrid vertical={false} strokeDasharray="1 1" />
                    <XAxis dataKey="name" stroke={''} tick={{ fill: tickColor }} />
                    <YAxis stroke={''} tick={{ fill: tickColor }} />
                    <Tooltip />

                    <Bar dataKey="Venda em Cash" fill="#525CEB" />
                    <Bar dataKey="Venda por TPA" fill="#9EC8B9" />
                    <Bar dataKey="Saída em Cash" fill="#F5004F" />

                  </BarChart>
                </ResponsiveContainer>
              </div>
            </div>
          </div>
          <div className="col-lg-6 mb-4">
            <div className="card card-charts">
              <div className="card-header p-3">
                <h4 className="m-0 mb-1">Clientes Ativos</h4>
                <p className="opacity-50 font-14">Número de clientes ativos ao longo do tempo.</p>
              </div>
              <div className="card-body p-0 pb-2 pe-4 pb-4" style={{ width: '100%', height: 270 }}>
                <ResponsiveContainer>
                  <LineChart
                    width={500}
                    height={270}
                    data={usersData}
                    className='cardsssaa'
                  >
                    <CartesianGrid vertical={false} strokeDasharray="1 1" />
                    <XAxis dataKey="name" stroke={''} tick={{ fill: tickColor }} />
                    <YAxis stroke={''} tick={{ fill: tickColor }} />
                    <Tooltip />

                    <Line type="monotone" dataKey="Usuários Ativos" stroke="#4CAF50" activeDot={{ r: 8 }} />
                    <Line type="monotone" dataKey="Usuários Desativados" stroke="#FF6347" />
                    <Line type="monotone" dataKey="Usuários em Espera" stroke="#FFD700" />
                  </LineChart>
                </ResponsiveContainer>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </main>
}
