import { HiCube, HiCurrencyDollar, HiUsers } from "react-icons/hi";
import { CardData } from "../../interface/cardData";

export const dataCard: CardData[] = [
    { title: 'Usuários', count: '100', percentage: 12, day: '1', status: 1, icon: HiUsers, color: 'FF7F3E' },
    { title: 'Produtos', count: '10', percentage: 8, day: '3', status: 0, icon: HiCube, color: '7E8EF1' },
    { title: 'Clientes', count: '20', percentage: 5, day: '4', status: 1, icon: HiUsers, color: '3ABEF9' },
    { title: 'Faturamento', count: '2000kz', percentage: 15, day: '5', status: 0, icon: HiCurrencyDollar, color: 'FEAE6F' }
    
  ];

 export const salesData = [
    { name: 'Jan', day: '01', 'Venda em Cash': 4000, 'Venda por TPA': 2400, 'Saída em Cash': 2400 },
    { name: 'Feb', day: '01', 'Venda em Cash': 3000, 'Venda por TPA': 1398, 'Saída em Cash': 2210 },
    { name: 'Mar', day: '01', 'Venda em Cash': 2000, 'Venda por TPA': 9800, 'Saída em Cash': 2290 },
    { name: 'Apr', day: '01', 'Venda em Cash': 2780, 'Venda por TPA': 3908, 'Saída em Cash': 2000 },
    { name: 'May', day: '01', 'Venda em Cash': 1890, 'Venda por TPA': 4800, 'Saída em Cash': 2181 },
    { name: 'Jun', day: '01', 'Venda em Cash': 2390, 'Venda por TPA': 3800, 'Saída em Cash': 2500 },
    { name: 'Jul', day: '01', 'Venda em Cash': 3490, 'Venda por TPA': 4300, 'Saída em Cash': 2100 },
  ];
  

  export const usersData = [
    { name: 'Jan', 'Usuários Ativos': 4000, 'Usuários Desativados': 2400, 'Usuários em Espera': 240 },
    { name: 'Feb', 'Usuários Ativos': 3000, 'Usuários Desativados': 1398, 'Usuários em Espera': 221 },
    { name: 'Mar', 'Usuários Ativos': 2000, 'Usuários Desativados': 9800, 'Usuários em Espera': 229 },
    { name: 'Apr', 'Usuários Ativos': 2780, 'Usuários Desativados': 3908, 'Usuários em Espera': 200 },
    { name: 'May', 'Usuários Ativos': 1890, 'Usuários Desativados': 4800, 'Usuários em Espera': 218 },
    { name: 'Jun', 'Usuários Ativos': 2390, 'Usuários Desativados': 3800, 'Usuários em Espera': 250 },
    { name: 'Jul', 'Usuários Ativos': 3490, 'Usuários Desativados': 4300, 'Usuários em Espera': 210 },
  ];
  
