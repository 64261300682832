import React from 'react'
import './style.scss'
import { CardData } from '../../interface/cardData'
import { HiOutlineTrendingDown, HiOutlineTrendingUp, HiUsers } from "react-icons/hi";
export const CardsData: React.FC <CardData> = (props:any) => {
  const IconComponent = props.icon; 
  return <div className={`cardData mb-3`}>
    <div className="card">
      <div className="card-body mt-2">      
        <div className='d-flex align-items-center'>
        <IconComponent className='icon' style={{ color: props.color }} />
          <h6>{props.title}</h6>
        </div>
        <div className='d-flex mt-1 justify-content-between align-items-center'>
        <h3>{props.count}</h3>
        <div className='trending'>
        {props.status === 1 ? (<><HiOutlineTrendingUp className='icontre' style={{ color: '#06D001' }}/><span style={{ color: '#06D001' }}>{props.percentage} %</span></>) : (<>< HiOutlineTrendingDown className='icontre' style={{ color: '#EE4E4E' }}/> <span style={{ color: 'red' }}>{props.percentage} %</span></>)  } <br />
        <span className='opacity-50 font-14'>ha {props.day} dias</span>
        </div>
        </div>
      </div>
    </div>
  </div>
}
