import React, { useEffect, useState } from 'react';
import { HiOutlineMoon } from 'react-icons/hi';
import { Bounce, toast } from 'react-toastify';

const DarkModeToggle: React.FC = () => {
    const [darkMode, setDarkMode] = useState(false);

    useEffect(() => {
        const savedMode = localStorage.getItem('theme');
        if (savedMode) {
            setDarkMode(savedMode === 'dark');
            document.body.classList.toggle('dark-mode', savedMode === 'dark');
        }
    }, []);

    const toggleDarkMode = () => {
        toast('Tema alterado com sucesso!', {
            position: "bottom-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            transition: Bounce,
        });
        const newMode = !darkMode;
        setDarkMode(newMode);
        localStorage.setItem('theme', newMode ? 'dark' : 'light');
        document.body.classList.toggle('dark-mode', newMode);
    };

    return (
        <div className="btn-nav-linkdard d-flex align-items-center">
            <HiOutlineMoon className="icon" />
            Modo Escuro
            <div className="form-check form-switch ms-2 mt-2">
                <input
                    className="form-check-input"
                    type="checkbox"
                    id="flexSwitchCheckDefault"
                    checked={darkMode}
                    onChange={toggleDarkMode}
                />
            </div>
        </div>
    );
};

export default DarkModeToggle;
