import { useEffect, useState } from 'react';
import { Dropdown, Modal } from 'react-bootstrap';
import { HiDotsHorizontal, HiPlus } from 'react-icons/hi';
import Menu from '../../components/menu';
import { NavTop } from '../../components/navTop';
import Table from '../../components/table';
import { TableRow } from '../../interface/tab';
import { api } from '../../services/api';
import './style.scss';

export default function Clients() {

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [rows, setRows] = useState<TableRow[]>([]);
    const [newCustomer, setNewCustomer] = useState({ id: null, name: '', email: '', phone: '', nif: '', address: '', country_id: 1, status: 'Ativo' });
    const [countries, setCountries] = useState([]);
    const [isEdit, setIsEdit] = useState(false);
    const [isLoadingUpdate, setIsLoadingUpdate] = useState(true);

    const columns = ['#', 'Nome', 'Email', 'Número', 'NIF', 'Localização', 'País', 'Status', 'Opções'];

    useEffect(() => {
        loadCustomers();
        loadCountries();
    }, []);

    async function loadCustomers() {
        try {
            const response = await api.get('/customers');
            const customers = response.data.data;

            const formattedRows = customers.map((customer: any, index: number) => ({
                id: customer.id,
                data: [
                    customer.name,
                    customer.email,
                    customer.phone,
                    customer.nif,
                    customer.address,
                    customer.country?.name || 'Indefinido',
                    customer.status,
                    <div>
                        <Dropdown>
                            <Dropdown.Toggle className='table-dropdown '>
                                <HiDotsHorizontal />
                            </Dropdown.Toggle>

                            <Dropdown.Menu className='table-dropdown-menu navDropdownToggle'>
                                <Dropdown.Item className='font-14' onClick={() => handleEdit(customer)}>Editar</Dropdown.Item>
                                <Dropdown.Item className='font-14' onClick={() => handleDelete(customer.id)}>Deletar</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    </div>
                ]
            }));
            setRows(formattedRows);
        } catch (error) {
            console.error("Error fetching customers:", error);
        }
    }

    async function loadCountries() {
        try {
            const response = await api.get('/countries');
            setCountries(response.data.data);
        } catch (error) {
            console.error("Error fetching countries:", error);
        }
    }

    async function editCustomer() {
        try {
            await api.put(`/customers/${newCustomer.id}`, newCustomer);
            loadCustomers();
            setNewCustomer({ id: null, name: '', email: '', phone: '', nif: '', address: '', country_id: 1, status: 'Ativo' });
            handleClose();

        } catch (error) {
            console.error("Error editing customer:", error);
        }
    }

    async function handleDelete(id: number) {
        try {
            await api.delete(`/customers/${id}`);
            loadCustomers();
            setNewCustomer({ id: null, name: '', email: '', phone: '', nif: '', address: '', country_id: 1, status: 'Ativo' });

        } catch (error) {
            console.error("Error deleting customer:", error);
        }
    }

    async function addCustomer() {
        try {
            await api.post('/customers', {
                name: newCustomer.name,
                email: newCustomer.email,
                phone: newCustomer.phone,
                nif: newCustomer.nif,
                address: newCustomer.address,
                country_id: newCustomer.country_id,
                status: newCustomer.status
            });

            loadCustomers();
            setNewCustomer({ id: null, name: '', email: '', phone: '', nif: '', address: '', country_id: 1, status: 'Ativo' });
            handleClose();
        } catch (error) {
            console.error("Error adding customer:", error);
        }
    }

    function handleEdit(customer: any) {
        setNewCustomer(customer);
        setIsEdit(true);
        handleShow();
    }

    function handleSave() {
        setIsLoadingUpdate(false);
        if (isEdit) {
            editCustomer();
        } else {
            addCustomer();
        }
        setIsLoadingUpdate(true);
    }

    function showAddForm() {
        setIsEdit(false);
        setNewCustomer({ id: null, name: '', email: '', phone: '', nif: '', address: '', country_id: 1, status: 'Ativo' });
        handleShow();
    }

    return (
        <main className='d-flex'>
            <Menu />
            <section className='w-100'>
                <NavTop title={'Clientes'} />
                <div className="container ps-4 pe-4 mt-5">
                    <Table
                        columns={columns}
                        rows={rows}
                        title={'Clientes'}
                        btn={<button type="button" className="btn btn-dark" onClick={showAddForm}><HiPlus /></button>}
                        heighTable={350}
                        btnGroup={undefined}
                        cardTopLeftRadius={10} />
                </div>
            </section>
            <Modal show={show} onHide={handleClose} backdrop="static" keyboard={false} className='modalprs'>
                <Modal.Header closeButton className='border-0'>
                    <h5 className='mt-2 mb-0'>Cadastro de Cliente</h5>
                </Modal.Header>
                <Modal.Body>
                    <div className="input-group mb-3">
                        <span className="input-group-text">Nome</span>
                        <input
                            type="text"
                            className="form-control"
                            placeholder="Nome do Cliente"
                            value={newCustomer.name}
                            onChange={(e) => setNewCustomer({ ...newCustomer, name: e.target.value })}
                        />
                    </div>
                    <div className="input-group mb-3">
                        <span className="input-group-text">Email</span>
                        <input
                            type="email"
                            className="form-control"
                            placeholder="Email do Cliente"
                            value={newCustomer.email}
                            onChange={(e) => setNewCustomer({ ...newCustomer, email: e.target.value })}
                        />
                    </div>
                    <div className="input-group mb-3">
                        <span className="input-group-text">Telefone</span>
                        <input
                            type="tel"
                            className="form-control"
                            placeholder="Número de Contato"
                            value={newCustomer.phone}
                            onChange={(e) => setNewCustomer({ ...newCustomer, phone: e.target.value })}
                        />
                    </div>
                    <div className="input-group mb-3">
                        <span className="input-group-text">NIF</span>
                        <input
                            type="text"
                            className="form-control"
                            placeholder="Número de Identificação Fiscal"
                            value={newCustomer.nif}
                            onChange={(e) => setNewCustomer({ ...newCustomer, nif: e.target.value })}
                        />
                    </div>
                    <div className="input-group mb-3">
                        <span className="input-group-text">Localização</span>
                        <input
                            type="text"
                            className="form-control"
                            placeholder="Localização do Cliente"
                            value={newCustomer.address}
                            onChange={(e) => setNewCustomer({ ...newCustomer, address: e.target.value })}
                        />
                    </div>
                    <div className="input-group mb-3">
                        <span className="input-group-text">País</span>
                        <select
                            className="form-control"
                            value={newCustomer.country_id}
                            onChange={(e) => setNewCustomer({ ...newCustomer, country_id: parseInt(e.target.value) })}
                        >
                            {countries.map((country: any) => (
                                <option key={country.id} value={country.id}>{country.name}</option>
                            ))}
                        </select>
                    </div>
                    <div className="input-group mb-3">
                        <span className="input-group-text">Status</span>
                        <select
                            className="form-control"
                            value={newCustomer.status}
                            onChange={(e) => setNewCustomer({ ...newCustomer, status: e.target.value })}
                        >
                            <option value="Ativo">Ativo</option>
                            <option value="Inativo">Inativo</option>
                        </select>
                    </div>
                </Modal.Body>
                <Modal.Footer className='border-0'>
                    <button className='btn btn-secondary' onClick={handleClose}>
                        Cancelar
                    </button>
                    <button className='btn btn-dark' onClick={handleSave}>
                        Salvar Alterações
                    </button>
                </Modal.Footer>
            </Modal>
        </main>
    );
}
