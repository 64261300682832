import { toast } from 'react-toastify';
import { api } from './api';

interface Credentials {
    email: string;
    password: string;
}

export async function login(credentials: Credentials): Promise<boolean> {
    try {
        const response = await api.post('/auth/login', credentials);
        if (response.status === 200) {
            const { access_token, refresh_token } = response.data.auth_data;
            localStorage.setItem('access_token', access_token);
            //localStorage.setItem('refresh_token', refresh_token);
            toast.success(response.data.message);
            return true;
        } else {
            toast.error("Erro: " + response.data.message);
            return false;
        }
    } catch (error) {
        console.error("Error during login:", error);
        toast.error("Erro ao tentar fazer login. Por favor, tente novamente.");
        return false;
    }
}

export function logout(): void {
    localStorage.removeItem('access_token');
    //localStorage.removeItem('refresh_token');
    toast.success('Logout realizado com sucesso.');
}
